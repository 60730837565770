<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('map')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{$t('map')}}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="map" class="map"></div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonText, IonSpinner } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';

import mapboxgl from "mapbox-gl";
import { onMounted } from "vue";


export default {
  name: 'Map',
  components: {  IonHeader, IonToolbar, IonTitle, IonContent, IonPage, HeaderOne, IonText, IonSpinner },
  data() {
    return {
      accessToken: 'pk.eyJ1IjoiaGFtbWVzbWVkaWEiLCJhIjoiY2s2c2dsbnJ2MGY4NjNnbnZzYmN4MHd0eiJ9.r0nHU6oMejybxGv9Y6IQkA',
      mapStyle: 'mapbox://styles/hammesmedia/ckkk6ci322iqc17o6itib2rvt',
      mapCenter: [52.411129, 12.537766],
    }
  },
  setup() {
    onMounted(() => {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiaGFtbWVzbWVkaWEiLCJhIjoiY2s2c2dsbnJ2MGY4NjNnbnZzYmN4MHd0eiJ9.r0nHU6oMejybxGv9Y6IQkA";
      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/hammesmedia/ckkk6ci322iqc17o6itib2rvt",
        center: [12.537766, 52.411129], // starting position [lng, lat]
        zoom: 16
      });
      map.addControl(new mapboxgl.FullscreenControl());
      map.on('load', () => {
        map.resize();
        /*map.addSource("usa", {
            type: "geojson",
            data: "https://raw.githubusercontent.com/johan/world.geo.json/master/countries/USA.geo.json",
        });
        map.addLayer({
            id: "usa-fill",
            type: "fill",
            source: "usa",
            paint: {
                "fill-color": "red",
            },
        });*/
      });
      const wwz = new mapboxgl.Marker({
        color: '#D0021B'
      })
        .setLngLat([12.536782765366363, 52.411817712060575])
        .setPopup(new mapboxgl.Popup({ offset: 15 }) // add popups
        .setHTML('<h4>WWZ &amp; FBW</h4>'))
        .addTo(map);

      const fbt = new mapboxgl.Marker({
        color: '#D0021B'
      })
        .setLngLat([12.537079891400095, 52.41089992222607])
        .setPopup(new mapboxgl.Popup({ offset: 15 }) // add popups
        .setHTML('<h4>FBT</h4>'))
        .addTo(map);

      const fbi = new mapboxgl.Marker({
        color: '#D0021B'
      })
        .setLngLat([12.53831370757992, 52.41100463458319])
        .setPopup(new mapboxgl.Popup({ offset: 15 }) // add popups
        .setHTML('<h4>FBI</h4>'))
        .addTo(map);
      
      const lib = new mapboxgl.Marker({
        color: '#D0021B'
      })
        .setLngLat([12.538345894088959, 52.41167544222349])
        .setPopup(new mapboxgl.Popup({ offset: 15 }) // add popups
        .setHTML('<h4>Bücherei</h4>'))
        .addTo(map);

      const rittersaal = new mapboxgl.Marker({
        color: '#D0021B'
      })
        .setLngLat([12.537868460871548, 52.41142020929939])
        .setPopup(new mapboxgl.Popup({ offset: 15 }) // add popups
        .setHTML('<h4>Rittersaal</h4>'))
        .addTo(map);

      const mensa = new mapboxgl.Marker({
        color: '#D0021B'
      })
        .setLngLat([12.537919612344753, 52.41027605617281])
        .setPopup(new mapboxgl.Popup({ offset: 15 }) // add popups
        .setHTML('<h4>Mensa &amp; AStA</h4>'))
        .addTo(map);

      const audimax = new mapboxgl.Marker({
        color: '#D0021B'
      })
        .setLngLat([12.539160318814181, 52.41112293106735])
        .setPopup(new mapboxgl.Popup({ offset: 15 }) // add popups
        .setHTML('<h4>Audimax</h4>'))
        .addTo(map);
    });
    return {};
  },
}
</script>

<style scoped>
#map { 
  /*position:absolute; 
  top:0; 
  bottom:0; */
  width:100%; 
  height: 100%;
  z-index: 0;
}
.mapboxgl-canvas {
  height: 100%;
}
.mapboxgl-popup-content {
  padding: 3px;
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
}
</style>